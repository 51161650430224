import styled from "@emotion/styled";

const Container = styled.div`
	padding: 0 1.7rem;

	section {
		background: rgba(226, 226, 226, 0.85);
		border-radius: 20px;
		margin-bottom: 34px;
		padding: 36px 21px 12px;

		b {
			font-size: 1.5rem;
			font-weight: 600;
			margin-bottom: 12px;
			display: block;
			text-align: center;
		}

		p {
			font-size: 1.2rem;
			line-height: 30px;
			margin: 0;
			text-align: center;
		}
	}
`;

export { Container };
